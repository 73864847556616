import { SUPER_ADMIN } from '../const.js'
import { mapState } from 'vuex'

export default (access) => {
  return {
    computed: {
      ...mapState(['user', 'actionsBusiness']),
    },
    mounted () {
      // // // // // // // // // // // // // // // // // // // // // // // // // // console.log('pase')
      // // // // // // // // // // // // // // // // // // // // // // // // // // console.log(this.user?.authorization)
      const auth = this.user?.authorization.filter(auth => auth.businessId === this.actionsBusiness)[0]
      // // // // // // // // // // // // // // // // // // // // // // // // // // // console.log('und', auth)
      // // // // // // // // // // // // // // // // // // // // // // // // // // console.log('Se cumple?', auth === undefined || (this.user?.authorization.filter(auth => auth.roleId === SUPER_ADMIN).length > 0 && !access.find((access) => access === auth.roleId)))
      // // // // // // // // // // // // // // // // // // // // // // // // // // console.log('authsUser', this.user?.authorization)
      // // // // // // // // // // // // // // // // // // // // // // // // // // console.log('auth', auth)
      // // // // // // // // // // // // // // // // // // // // // // // // // // console.log('role', this.user?.authorization.filter(auth => auth.roleId === SUPER_ADMIN).length > 0)
      if (this.user?.authorization.filter(auth => auth.roleId === SUPER_ADMIN).length <= 0) {
        if (auth === undefined || !access.find((access) => access === auth.roleId && auth.businessId === this.actionsBusiness)) {
          this.$router.push('/forbidden')
          // // // // // // // // // // // // // // // // // // // // // // // // // // console.log('te vas de aqui')
        }
      }
      // } else if (!access.find((access) => access === auth.roleId && auth.businessId === this.actionsBusiness)) {
      //   this.$router.push('/forbidden')
      //   // // // // // // // // // // // // // // // // // // // // // // // // // // console.log('y tu tambien')
      // }
      // if (auth === undefined || (this.user?.authorization.filter(auth => auth.roleId === SUPER_ADMIN).length > 0 && !access.find((access) => access === auth.roleId))) {
      //   // // // // // // // // // // // // // // // // // // // // // // // // // // console.log('entre')
      //   this.$router.push('/forbidden')
      // }
    },
  }
}
