<template>
  <v-dialog
    v-model="dialog"
    max-width="490"
    @click:outside="close"
  >
    <v-card>
      <v-card-title class="text-h2">
        ¿Esta seguro de querer eliminar este cliente?
      </v-card-title>

      <v-card-text class="text-center grey--text">
        Por motivos de seguridad el cliente sera inabilitado de la aplicación, mas no eliminado
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="red darken-1"
          text
          @click="close"
        >
          Cancelar
        </v-btn>

        <v-btn
          color="green darken-1"
          text
          :loading="fetchingUser"
          @click="toggle"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'AcceptDialog',

    props: {
      dialog: Boolean,
      item: {
        type: Object,
        default: () => null,
      },
    },

    computed: {
      ...mapState(['fetchingUser', 'success']),
    },

    watch: {
      success (v) {
        this.$emit('close', v)
      },
    },

    methods: {
      ...mapActions(['deactiveClients']),
      close () {
        this.$emit('close', '')
      },
      toggle () {
        // // // // // // // // // // // // // // // // // // // // // // // // // // // console.log(this.item)
        this.deactiveClients(this.item)
      },
    },
  }
</script>

<style>

</style>
